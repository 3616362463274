.MainBackground {
  background: radial-gradient(
    70.71% 76.71% at 57% 50%,
    rgba(189, 0, 255, 0.3) 0%,
    rgba(0, 0, 0, 0) 66%
  );
}

.secondBackground {
  background: radial-gradient(
    74.71% 190.71% at 22% 55%,
    #0076f0 -90%,
    rgba(0, 0, 0, 0) 26%
  );
}

.secondBackground-ar {
  background: radial-gradient(
    74.71% 210.71% at 81% 55%,
    #0076f0 -80%,
    rgba(0, 0, 0, 0) 26%
  );
}

.colorFooter {
  background: linear-gradient(
    180deg,
    #af020b 0%,
    #af020b 16.67%,
    #fff 50%,
    #0fc2b4 100%
  );
  -webkit-background-clip: text;
  color: transparent;
}

.colorBlockchain {
  background: var(
    --golden-gradient,
    linear-gradient(180deg, #907a01 0%, #c7b339 50.52%, #8e7d01 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.borderBlockchain {
  border-color: #907a01;
}

.colorBtn {
  background: linear-gradient(180deg, #0076f0 0%, #00def0 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.borderBtn {
  border-color: #0076f0;
  border-bottom-color: #1aa6df;
}

.btnBookFree {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.sky_blue_linear {
  color: linear-gradient(180deg, #0076f0 0%, #00def0 100%);
}

.BackgroundBtn {
  background: rgb(255 255 255 / 15%);
}

body {
  background-color: black;
  color: white;
}
.font-arabic {
  font-family: "Inter", sans-serif;
}

.font-english {
  font-family: "Tajawal", sans-serif;
}

html {
  scroll-behavior: smooth;
}

#start div div input {
  width: 100px;
}

.blockchain {
  background: var(
    --sky-blue-linear,
    linear-gradient(180deg, #0076f0 0%, #00def0 100%)
  );
}

.shadow-calendar {
  box-shadow: 0px 4.24552059173584px 59.4372px rgba(0, 0, 0, 0.1);
}
